import React from 'react';
import Dashboard from './Dashboard';
import * as Icon from '@mui/icons-material'
import MyAccount from './MyAccount';

export interface ScreenProps {
  path: string
  name: string
  component: React.ReactNode
  icon?: JSX.Element
  hideSideBar?: boolean
}

const screens: ScreenProps[] = [
  {
    name: 'Dashboard',
    path: '/',
    component: <Dashboard/>,
    icon: <Icon.Home/>
  },
  {
    name: 'Minha Conta',
    path: 'my_account',
    component: <MyAccount/>,
    hideSideBar: true
  }
];
export default screens;
