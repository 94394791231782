import React from 'react';
import { Box, Button, Card, CardContent, CardHeader, Container, Divider, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getAuth, updatePassword, updateProfile } from 'firebase/auth';
import { setLoading } from '../../utils/loadingState';
import { toast } from 'react-toastify';

interface IData {
  fullName: string
  email: string
  password?: string
}

const MyAccount: React.FC = () => {
  const { currentUser } = getAuth();

  const formik = useFormik({
    initialValues: {
      fullName: currentUser?.displayName ?? '',
      email: currentUser?.email ?? '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      fullName: Yup
        .string()
        .max(50)
        .required('Nome é obrigatório'),
      password: Yup
        .string()
        .max(255)
        .notRequired()
        .min(12, 'A senha deve conter ao menos 12 caracteres')
        .matches(/[a-z]/, 'A senha deve conter letras minúsculas')
        .matches(/[A-Z]/, 'A senha deve conter letras maiúsculas')
        .matches(/[0-9]/, 'A senha deve conter números')
        .matches(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, 'A senha deve conter caracteres especiais'),
      confirmPassword: Yup
        .string()
        .max(255)
        .notRequired()
        .min(12, 'A senha deve conter ao menos 12 caracteres')
        .matches(/[a-z]/, 'A senha deve conter letras minúsculas')
        .matches(/[A-Z]/, 'A senha deve conter letras maiúsculas')
        .matches(/[0-9]/, 'A senha deve conter números')
        .matches(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, 'A senha deve conter caracteres especiais')
        .test('passwords-match', 'As senhas não coincidem', function(value) {
          return this.parent.password === value
        })
    }),
    onSubmit: (values) => submitForm(values)
  });

  const submitForm = (data: IData) => {
    setLoading(true);
    if (currentUser) {
      updateProfile(currentUser, {
        displayName: data.fullName
      }).then(() => {
        if (data.password) {
          updatePassword(currentUser, data.password)
            .then(() => toast.success('Dados alterados com sucesso!'))
            .catch(error => {
              console.log(error.message)
              toast.error('Erro ao alterar a senha. Entre em contato com a administrador.')
            })
            .finally(() => setLoading(false));
        } else {
          toast.success('Dados alterados com sucesso!')
          setLoading(false);
        }
      })
    }
  }

  return (
    <Box
      component='main'
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth='lg'>
        <Grid
          container
          spacing={3}
        >
          <Grid item
            display='flex'
            justifyContent='center'
            xs={12}
          >
            <form
              autoComplete='off'
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <Card>
                <CardHeader
                  title='Perfil'
                  subheader='As informações podem ser alteradas'
                />
                <Divider/>
                <CardContent>
                <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(formik.touched.fullName && formik.errors.fullName)}
                        fullWidth
                        helperText={formik.touched.fullName && formik.errors.fullName}
                        label='Nome'
                        name='fullName'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.fullName}
                        variant='outlined'
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        disabled
                        error={Boolean(formik.touched.email && formik.errors.email)}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        label='E-mail'
                        name='email'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type='email'
                        value={formik.values.email}
                        variant='outlined'
                        autoFocus
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(formik.touched.password && formik.errors.password)}
                        fullWidth
                        helperText={formik.touched.password && formik.errors.password}
                        label='Nova senha'
                        name='password'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type='password'
                        value={formik.values.password}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                        fullWidth
                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        label='Confirmar nova senha'
                        name='confirmPassword'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type='password'
                        value={formik.values.confirmPassword}
                        variant='outlined'
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                  }}
                >
                  <Button
                    color='primary'
                    variant='contained'
                    type='submit'
                  >
                    Save details
                  </Button>
                </Box>
              </Card>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
export default MyAccount;
