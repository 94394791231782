import React from 'react';
import styled from '@emotion/styled';
import { AppBar, Avatar, Box, IconButton, Menu, MenuItem, Toolbar, Tooltip } from '@mui/material';
import * as Icon from '@mui/icons-material'
import { auth } from '../../../services/firebase';
import { NavLink, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

interface Props {
  sidebarOnOpen: () => void
}

const Root = styled(AppBar)(({ theme }:any) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}))

const Navbar:React.FC<Props> = ({
  sidebarOnOpen
}) => {
  const { currentUser } = auth;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    signOut(getAuth());
    navigate('/');
  }
  return (
    <>
      <Root
        sx={{
          left: {
            lg: 280
          },
          width: {
            lg: 'calc(100% - 280px)'
          }
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
          <IconButton
            onClick={sidebarOnOpen}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none'
              }
            }}
          >
            <Icon.Menu fontSize='small'/>
          </IconButton>
          <Tooltip title='Search'>
            <IconButton sx={{ ml: 1 }}>
              <Icon.Search fontSize='small' />
            </IconButton>
          </Tooltip>
          <Box sx={{ flexGrow: 1 }} />
          {currentUser?.displayName && (
            <IconButton
              onClick={handleMenu}
            >
              <Avatar
                sx={{}}
              >
                {currentUser.displayName.substring(0,1)}
              </Avatar>
            </IconButton>
          )}
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              component={NavLink}
              to='/my_account'
              onClick={handleClose}
            >Minha Conta</MenuItem>
          </Menu>
          <Tooltip title='Sair'>
            <IconButton sx={{ ml: 1 }} onClick={logOut}>
              <Icon.Logout fontSize='small' />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </Root>
    </>
  )
}
export default Navbar;
